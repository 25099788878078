// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.board-space-unit-title, .board-space-unit-title-placeholder {
    height: 33%;
    width: 100%;
}

.board-space-unit-title {
    background: #333;
    color: #fff;
}

.board-space-unit-title-inner {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.board-space-attribute-featured {
    flex-grow: 1;
    width: 100%;
}

.unit-details-title-wrapper {
    display: flex;
    border-bottom: 1px solid;
}

.unit-details-take-action {
    padding-top: 10px;
    padding-bottom: 5px;
}

.unit-details-title-type {
    font-size: 0.8em;
    display: flex;
    align-items: end;
    margin-left: 10px;
}

.board-space-unit {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.board-space-unit-badge {
    padding: 0px 0.2em;
    font-size: 0.8em;
}

.board-space-unit-badges {
    display: flex;
    height: 1em;
}

.board-space-unit-badges .separator {
    flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/ui/game_state/unit-tile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".board-space-unit-title, .board-space-unit-title-placeholder {\n    height: 33%;\n    width: 100%;\n}\n\n.board-space-unit-title {\n    background: #333;\n    color: #fff;\n}\n\n.board-space-unit-title-inner {\n    width: 100%;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    text-align: center;\n}\n\n.board-space-attribute-featured {\n    flex-grow: 1;\n    width: 100%;\n}\n\n.unit-details-title-wrapper {\n    display: flex;\n    border-bottom: 1px solid;\n}\n\n.unit-details-take-action {\n    padding-top: 10px;\n    padding-bottom: 5px;\n}\n\n.unit-details-title-type {\n    font-size: 0.8em;\n    display: flex;\n    align-items: end;\n    margin-left: 10px;\n}\n\n.board-space-unit {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n}\n\n.board-space-unit-badge {\n    padding: 0px 0.2em;\n    font-size: 0.8em;\n}\n\n.board-space-unit-badges {\n    display: flex;\n    height: 1em;\n}\n\n.board-space-unit-badges .separator {\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
